<template>
    <BT-Blade-Items
        addBladeName="delivery-archive"
        bladeName="delivery-archives"
        :bladesData="bladesData"
        :headers="[
            { text: 'Delivered On', value: 'deliveredOn', textFilter: 'toShortDateAndTime', subtitle: 4 },
            { text: 'From', value: 'departureLocationID', display: true, subtitle: 1, prefix: 'From: ' },
            { text: 'To', value: 'destinationLocationID', display: true, subtitle: 2, prefix: 'To: ' }]"
        hideActions
        :itemProperties="['ID', 'DepartureLocationID', 'DestinationLocationID', 'CourierID', 'SellerID', 'DeliveredOn', 'ReceiverName']"
        minimizeOnSelect
        navigation="delivery-archives"
        title="Archived Deliveries"
        useServerPagination>
        <template v-slot:departureLocationID="{ item }">
            <BT-Entity 
                navigation="public-locations"
                :itemValue="item.departureLocationID"
                single
                textFilter="toFamiliarLocationLine"
                useLocalCache />
        </template>
        <template v-slot:destinationLocationID="{ item }">
            <BT-Entity 
                navigation="public-locations"
                :itemValue="item.destinationLocationID"
                single
                textFilter="toFamiliarLocationLine"
                useLocalCache />
        </template>
        <template v-slot:sellerID="{ item }">
            <div v-if="item.sellerID == item.courierID">
                Self
            </div>
            <BT-Entity 
                v-else
                navigation="clients"
                :itemValue="item.sellerID"
                itemText="companyName" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Delivery-Archives-Blade',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    }
}
</script>