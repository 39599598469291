var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"delivery-archive","bladeName":"delivery-archives","bladesData":_vm.bladesData,"headers":[
        { text: 'Delivered On', value: 'deliveredOn', textFilter: 'toShortDateAndTime', subtitle: 4 },
        { text: 'From', value: 'departureLocationID', display: true, subtitle: 1, prefix: 'From: ' },
        { text: 'To', value: 'destinationLocationID', display: true, subtitle: 2, prefix: 'To: ' }],"hideActions":"","itemProperties":['ID', 'DepartureLocationID', 'DestinationLocationID', 'CourierID', 'SellerID', 'DeliveredOn', 'ReceiverName'],"minimizeOnSelect":"","navigation":"delivery-archives","title":"Archived Deliveries","useServerPagination":""},scopedSlots:_vm._u([{key:"departureLocationID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"public-locations","itemValue":item.departureLocationID,"single":"","textFilter":"toFamiliarLocationLine","useLocalCache":""}})]}},{key:"destinationLocationID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"public-locations","itemValue":item.destinationLocationID,"single":"","textFilter":"toFamiliarLocationLine","useLocalCache":""}})]}},{key:"sellerID",fn:function(ref){
        var item = ref.item;
return [(item.sellerID == item.courierID)?_c('div',[_vm._v(" Self ")]):_c('BT-Entity',{attrs:{"navigation":"clients","itemValue":item.sellerID,"itemText":"companyName"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }